<template>
  <div class="latest-news-banner-slot__wrapper">
    <a-visibility hide :on="[$breakpoint.mobile]">
      <div
        :style="featuredMediaStyles"
        class="latest-news-banner-slot__mocked-article-media"
      >
        media
      </div>
    </a-visibility>
    <a-visibility hide :on="[$breakpoint.mobile]">
      <div class="latest-news-banner-slot__content-size">Content</div>
    </a-visibility>
    <i class="latest-news-banner-slot__delimiter" />
    <div class="latest-news-banner-slot__banner-wrapper rounded-corners">
      <a-mocked-events-banner
        :size="$options.consts.BANNER_SIZE.SMALL_320x250"
        class="rounded-corners"
      />
    </div>
    <a-visibility show :on="[$breakpoint.mobile]">
      <i class="latest-news-banner-slot__delimiter bottom" />
    </a-visibility>
  </div>
</template>

<script>
import { aspectRatios } from 'enums/aspectRatios'
import AMockedEventsBanner, {
  SIZE as BANNER_SIZE
} from '@/components/_homepage/Shared/AMockedEventsBanner'

export default {
  name: 'HomepageLatestNewsBannerSlot',
  components: { AMockedEventsBanner },
  consts: { BANNER_SIZE },
  computed: {
    featuredMediaStyles() {
      return this.$helper.generateAspectRatioStyle(aspectRatios.article)
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/scss/local/mixins/homepage-latest-news';
@import '~assets/scss/global/mixins/figures';

.latest-news-banner-slot__wrapper {
  position: relative;

  .latest-news-banner-slot__mocked-article-media {
    visibility: hidden;
  }

  .latest-news-banner-slot__content-size {
    visibility: hidden;
    height: getArticleContentWithoutMedia(2);

    @include desktop-md {
      height: getArticleContentWithoutMedia(4);
    }

    @include tablet {
      height: getArticleContentWithoutMedia(2);
    }
  }

  .latest-news-banner-slot__delimiter {
    @include delimiter($mt: 13px, $mb: $article-slot-bottom-margin);

    @include mobile {
      margin-top: 0;
      margin-bottom: 20px;
    }

    &.bottom {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  .latest-news-banner-slot__banner-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @include desktop-md {
      top: -$featured-media-margin-top - $category-name-line-height / 2;
    }

    @include desktop-sm {
      top: 0;
    }

    @include mobile {
      position: relative;
      top: auto;
      left: auto;
      transform: unset;
      height: auto;
    }
  }
}
</style>
